import fpApp from '../../public/static/img/pages/home/fp-app.jpg';
import Image from '../Image';

import SC from './homeElements';

const FpAppSection = () => {
  const QUERY_PARAM1 = 'categoryId=25&backstack=full&adj_t=85ijo4l';
  const QUERY_PARAM2 =
    '&adj_fallback=https%3A%2F%2Fwww.fairprice.com.sg%2Fevents%2Fin-store%2Frewards-on-the-fairprice-app';
  const QUERY_PARAM3 =
    '&adj_redirect_macos=https%3A%2F%2Fwww.fairprice.com.sg%2Fevents%2Fin-store%2Frewards-on-the-fairprice-app';
  return (
    <SC.StyledContain className="text-center">
      <SC.Row flexDirection={['column', 'row']}>
        <SC.Col flexBasis={['0', '52%']}>
          <Image
            alt="Fp App"
            src={fpApp}
            height="436"
            width="400"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw,33vw"
          />
        </SC.Col>
        <SC.Col flexBasis={['0', '48%']} textAlign="left" alignSelf="center">
          <h2>Get more of what you love on the FairPrice Group app</h2>
          <p>
            The FairPrice Group app brings you closer to a more rewarding life at your fingertips.
            Earn and redeem Linkpoints, turn Linkpoints into exclusive rewards, and manage
            Linkpoints on the go.
          </p>
          <SC.StyledButton href="https://xdfz.adj.st/accounts?adj_t=18vr2bp" target="_blank">
            Download app
          </SC.StyledButton>
          <SC.StyledButton
            href={`${process.env.NEXT_PUBLIC_WP_URL}/earn-linkpoints-with-fairprice-group-app/?utm_source=link-website&utm_medium=homepage&utm_campaign=digital-earn-burn-20231013`}
            target="_blank"
            margin
          >
            Get Linkpoints
          </SC.StyledButton>
          <SC.StyledButton
            href={`https://xdfz.adj.st/loyalty/rewards-catalogue?${QUERY_PARAM1}${QUERY_PARAM2}${QUERY_PARAM3}`}
            target="_blank"
            margin
          >
            Redeem rewards
          </SC.StyledButton>
        </SC.Col>
      </SC.Row>
    </SC.StyledContain>
  );
};

export default FpAppSection;
