const transformHomeData = (homePageDetails) => {
  const { sections } = homePageDetails;
  const filteredData = sections?.filter((data) =>
    ['carousel', 'promotions'].includes(data.viewType),
  );

  const slides = filteredData?.[0]?.data?.list || [];
  const promotions =
    filteredData?.[1]?.data?.list?.map(
      ({ id, slug, name, partner, imgUrls, startDate, endDate }) => ({
        id,
        permalink: slug,
        name,
        logo: partner?.imgUrls?.logo || '',
        banner: imgUrls?.landscapeBanner || '',
        startDate,
        endDate,
      }),
    ) || [];

  return {
    slides,
    promotions,
  };
};
export default transformHomeData;
