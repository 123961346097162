import React from 'react';
import styled, { css } from 'styled-components';

import { theme } from '../GlobalStyles';
import { Box, Flex } from '../Grid';

const StyledBox = styled(Box)`
  width: 100%;
  ${theme.mediaQueries.below.small} {
    &.rail-class {
      max-width: 66%;
    }
  }
`;

const CardGrid = ({ rail, currentPromo, children }) => {
  return (
    <Flex
      css={css`
        overflow-x: ${rail ? 'auto' : 'initial'};
        flex-wrap: ${rail ? 'nowrap' : 'wrap'};
        width: 100%;
        margin-bottom: 1rem;
      `}
      mx="-5px"
    >
      {React.Children.map(children, (Child) => (
        <StyledBox
          className={rail ? 'rail-class' : ''}
          flexShrink="0"
          maxWidth={currentPromo ? ['50%', '33%'] : ['50%', '33%', '25%']}
          px="5px"
          mb="10px"
        >
          {Child}
        </StyledBox>
      ))}
    </Flex>
  );
};

export default CardGrid;
