import Slider from 'react-slick';
import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const StyledSlider = styled(Slider)`
  position: relative;
  a.button {
    display: inline-block;
  }
  h2 {
    color: ${theme.colors.purple};
  }
  p {
    color: ${theme.colors.black};
  }
  .slick-list,
  .slick-slider {
    position: relative;
  }

  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: 100%;
    min-height: 1px;
    > div {
      display: flex;
    }
    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .slick-active {
    z-index: 1;
  }

  .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    bottom: 47%;
    transform: translateX(-50%);
    font-size: 0;
    line-height: 0;
    z-index: 2;
    ${theme.mediaQueries.above.large} {
      left: 6%;
      bottom: 5%;
    }
    li {
      display: inline-block;
      vertical-align: middle;
    }
    button {
      cursor: pointer;
      border: 5px solid transparent;
      background-color: #eeeeee;
      background-clip: content-box;
      padding: 0;
      border-radius: 100%;
      text-indent: 100%;
      overflow: hidden;
      width: 1.25rem;
      height: 1.25rem;
      transition: background-color 150ms ease-in-out;
    }
    li button:hover {
      background-color: rgba(124, 34, 201, 0.25);
    }
    .slick-active button,
    .slick-active button:hover {
      background-color: ${(props) => props.theme.colors.purple};
    }
  }
  img,
  a {
    display: block;
  }
`;

export default StyledSlider;
