import Image from '../Image';

import Hero from './Hero';
import SC from './homeElements';
import StyledSlider from './StyledSlider';

const HeroSection = ({ slides }) => {
  return (
    <Hero className="contain">
      <div className="carousel">
        <StyledSlider
          linear
          dots
          infinite
          slidesToShow={1}
          slidesToSscroll={1}
          arrows={false}
          autoplay
          autoplaySpeed={5000}
        >
          {slides.map((slide, i) => (
            <div key={i}>
              <SC.StyledRow>
                <SC.StyledImgCol>
                  <a
                    href={slide.link}
                    className="gtm-carousel-banner"
                    id={`carousel-index-${i + 1}`}
                  >
                    <Image
                      alt={slide?.title}
                      src={slide?.imgUrls.carouselBanner}
                      width={800}
                      height={470}
                      sizes="(max-width: 1024px) 100vw, 65vw"
                    />
                  </a>
                </SC.StyledImgCol>
                <SC.StyledTextCol>
                  <SC.Box p="md" pt={['lg', '0']}>
                    <h2>{slide?.title}</h2>
                    <p>{slide?.subliner}</p>
                    <SC.LearnMoreButton
                      href={slide.link}
                      secondary
                      className="gtm-carousel-button"
                      id={`carousel-index-${i + 1}`}
                    >
                      Find out more
                    </SC.LearnMoreButton>
                  </SC.Box>
                </SC.StyledTextCol>
              </SC.StyledRow>
            </div>
          ))}
        </StyledSlider>
      </div>
    </Hero>
  );
};

export default HeroSection;
