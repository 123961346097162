import Link from 'next/link';
import styled from 'styled-components';

import useFormatDate from '../../utils/useFormatDate';
import Button from '../Button';
import { theme } from '../GlobalStyles';
import Image from '../Image';

const StyledCard = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .logo {
    max-height: 100px;
    height: 100px;
    margin: 1rem auto;
  }

  .banner {
    position: relative;
    padding-bottom: 52.356021%;
    align-self: stretch;
  }

  .category {
    background: #7c22c9;
    color: #fff;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    margin: 0 auto 1rem;
  }
  h3 {
    padding: 0 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-width: 100%;
    height: 3.55rem;
    text-align: center;
    word-break: break-word;
  }

  .promo-period {
    font-weight: 600;
    color: #666;
    margin-bottom: 1rem;
    font-size: 0.7688rem;
    text-align: center;
  }
  Button {
    padding: 0.275rem;
    font-size: 0.875rem;
    min-width: 5.5rem;
  }

  ${theme.mediaQueries.below.small} {
    h3 {
      height: 2.85rem;
    }
  }
`;

const Card = ({
  type,
  name,
  logo,
  banner,
  category,
  startDate,
  endDate,
  permalink,
  buttonValue,
}) => {
  const { formattedStartDate, formattedEndDate } = useFormatDate(startDate, endDate);

  return (
    <Link
      href={
        type === 'promotion' ? '/discover/promotion/[permalink]' : '/discover/partner/[permalink]'
      }
      as={
        type === 'promotion' ? `/discover/promotion/${permalink}` : `/discover/partner/${permalink}`
      }
    >
      <StyledCard>
        <div className="banner">
          <Image
            fill
            alt={name}
            src={banner}
            className="object-cover"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw,33vw"
          />
        </div>
        {logo && <img className="logo" alt={name} src={logo} />}
        {category && <div className="category">{category}</div>}
        <h3>{name}</h3>
        {formattedStartDate && formattedEndDate && (
          <div className="promo-period">
            {formattedStartDate} - {formattedEndDate}
          </div>
        )}
        {buttonValue && <Button mb="md">{buttonValue}</Button>}
      </StyledCard>
    </Link>
  );
};

export default Card;
