/* eslint-disable import/no-anonymous-default-export */
import styled from 'styled-components';

import Button from '../Button';
import { theme } from '../GlobalStyles';
import { Box, Col, Contain, Row } from '../Grid';

const SC = {};

SC.StyledButton = styled(Button)`
  padding: 0.75rem 1.125rem;
  border-radius: 1.5rem;
  width: 100%;
  ${(props) => props.margin && 'margin-top: 0.5rem;'}

  ${theme.mediaQueries.above.small} {
    width: auto;
  }
  ${theme.mediaQueries.above.large} {
    ${(props) => props.margin && ' margin-left: 0.5rem;'}
  }
`;
SC.StyledBox = styled(Box)`
  background: #ffb5e5;
  padding-bottom: 4rem;
  margin: 4rem 0 -3.75rem;
`;

SC.StyledContain = styled(Contain)`
  margin-top: 2rem;

  h2 {
    color: ${theme.colors.purple};
    font-family: 'Overpass';
    margin-bottom: 0;
  }
  p {
    color: #333333;
  }
  ${theme.mediaQueries.above.small} {
    margin-top: 4rem;
  }
`;

SC.StyledRow = styled(Row)`
  flex-direction: column;
  ${theme.mediaQueries.above.large} {
    flex-direction: row-reverse;
  }
`;

SC.StyledImgCol = styled(Col)`
  ${theme.mediaQueries.above.large} {
    width: 65%;
  }
`;

SC.StyledTextCol = styled(Col)`
  align-self: center;
  ${theme.mediaQueries.above.large} {
    width: 35%;
  }
`;

SC.LearnMoreButton = styled(Button)`
  display: inline-block;
  padding: 0.75rem 1.125rem;
  border-radius: 1.5rem;
  width: 100%;
  color: ${theme.colors.purple};
  &.is-active,
  &:hover {
    background: ${theme.colors.purple};
    color: #ffffff;
  }
  ${theme.mediaQueries.above.small} {
    width: auto;
  }
`;

SC.EarnRate = styled(Box)`
  margin: 2rem auto 0;
  text-align: center;
  background-color: #e5d4e9;
  cursor: pointer;
`;

const homeElements = {
  ...SC,
  Contain,
  Box,
  Row,
  Col,
};

export default homeElements;
