import { useEffect, useReducer } from 'react';
import moment from 'moment';

export const formatDate = (date) => moment(date).format('D MMM YYYY');

const reducer = (state, action) => {
  return {
    ...state,
    formattedStartDate: action.startDate,
    formattedEndDate: action.endDate,
  };
};

const useFormatDate = (startDate, endDate) => {
  const initialState = {
    formattedStartDate: '',
    formattedEndDate: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });
  }, [startDate, endDate]);

  return {
    formattedStartDate: state.formattedStartDate,
    formattedEndDate: state.formattedEndDate,
  };
};

export default useFormatDate;
