import memberBenefits from '../../public/static/img/pages/home/member-benefits.svg';
import Image from '../Image';

import SC from './homeElements';

const MemberBenefitsSection = () => {
  return (
    <SC.StyledContain className="text-center">
      <SC.Row flexDirection={['column', 'row']}>
        <SC.Col flexBasis={['0', '45%']} textAlign="left">
          <Image
            alt="Member benefits"
            src={memberBenefits}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw,33vw"
          />
        </SC.Col>
        <SC.Col flexBasis={['0', '55%']} textAlign="left" alignSelf="center">
          <h2>Explore Member Benefits</h2>
          <p>
            Sign up as a member for FREE and be the first to know about all the best promotions! You
            can get first dibs on exclusive deals to enjoy even greater value every day.
          </p>
          <SC.StyledButton href={`${process.env.NEXT_PUBLIC_WP_URL}/member-benefits/`}>
            Learn More
          </SC.StyledButton>
        </SC.Col>
      </SC.Row>
    </SC.StyledContain>
  );
};

export default MemberBenefitsSection;
