import React, { Fragment } from 'react';
import Link from 'next/link';

import SC from './homeElements';

const EarnRateSection = () => (
  <Fragment>
    <SC.EarnRate>
      <SC.Contain noPadding>
        <Link
          href="/all-partners?utm_source=link-website&utm_medium=website&utm_campaign=partner-earn-rate-homepage"
          legacyBehavior
        >
          <img src="/static/img/pages/home/earn-rate-banner.jpg" alt="Earn Rate" />
        </Link>
      </SC.Contain>
    </SC.EarnRate>
  </Fragment>
);

export default EarnRateSection;
