import React from 'react';
import { datadogRum } from '@datadog/browser-rum-slim';

import HomePage from '../components/Home';
import SeoHead from '../components/SeoHead';
import { getHomeData } from '../services/api';
import transformHomeData from '../utils/transformHomeData';
import withErrorHandling from '../utils/withErrorHandling';

import ErrorPage from './_error';

const IndexPage = ({ error, ...rest }) => {
  if (error) {
    datadogRum.addError(new Error(error.title));
    return <ErrorPage error={error} />;
  }
  return (
    <div>
      <SeoHead
        title="Link Rewards Programme | Always Rewarding"
        description="Plus! is now Link. Always rewarding members with the best promotions and experiences every day with over 1,000 partners’ outlets in Singapore."
      >
        <meta property="og:url" content={process.env.NEXT_PUBLIC_SITE_URL} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
                "@context": "http://schema.org",
                "@type": "WebSite",
                "url": "${process.env.NEXT_PUBLIC_SITE_URL}"
              }`,
          }}
        />
      </SeoHead>
      <HomePage {...rest} />
    </div>
  );
};

export const fetchHomePageData = async () => {
  const homePageDetails = transformHomeData(await getHomeData());
  return {
    props: {
      homePageDetails,
    },
  };
};

export const getServerSideProps = withErrorHandling(fetchHomePageData);

export default IndexPage;
