import React, { Fragment } from 'react';

import EarnRateSection from './EarnRateSection';
import FpAppSection from './FpAppSection';
import HeroSection from './HeroSection';
import MemberBenefitsSection from './MemberBenefitsSection';
import PromotionsSection from './PromotionsSection';

const Home = ({ homePageDetails }) => {
  const { promotions, slides } = homePageDetails;
  return (
    <Fragment>
      {slides && <HeroSection slides={slides} />}
      <FpAppSection />
      <EarnRateSection />
      <MemberBenefitsSection />
      {promotions && <PromotionsSection promotions={promotions} />}
    </Fragment>
  );
};

export default Home;
