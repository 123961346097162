import Card from '../../components/Cards/Card';
import CardGrid from '../../components/Cards/CardGrid';

import SC from './homeElements';

const PromotionsSection = ({ promotions }) => {
  const size = 4;
  const promotionItems = promotions.slice(0, size);
  return (
    <SC.StyledBox>
      <SC.StyledContain className="text-center">
        <SC.Row justifyContent="center">
          <SC.Col mb="m">
            <h2>What's New?</h2>
            <p>Check out the latest promotions from our partners.</p>
          </SC.Col>
        </SC.Row>
        <CardGrid>
          {promotionItems.map(({ permalink, id, name, logo, banner, startDate, endDate }) => (
            <Card
              type="promotion"
              permalink={permalink}
              id={id}
              key={id}
              name={name}
              logo={logo}
              banner={banner}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </CardGrid>
        <SC.Row flexDirection={['column']}>
          <SC.Col mt="md">
            <SC.StyledButton href="/all-promotions">View All Promotions</SC.StyledButton>
          </SC.Col>
        </SC.Row>
      </SC.StyledContain>
    </SC.StyledBox>
  );
};

export default PromotionsSection;
