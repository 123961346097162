import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 80rem;

  ${theme.mediaQueries.above.large} {
    flex-direction: row;
    padding: 0 1rem;
    .carousel {
      width: 100%;
    }
  }
`;

export default Hero;
